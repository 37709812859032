import React from 'react'
import { INLINES } from '@contentful/rich-text-types'
import { Link } from 'gatsby'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { StaticPage } from '../types/PgPages'

export const useStaticPageTemplate = ({
  data: {
    allContentfulPgPage: { edges },
  },
}: StaticPage) => {
  const { title: pageTitle, template } = edges[0].node
  const { title, preTitle, content, customHtml, description } = template

  const options: Options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node: any) => {
        return (
          <Link to={node.data.target.fields.file.es.url} target="_blank">
            {node.content.length ? node.content[0].value : null}
          </Link>
        )
      },
    },
  }

  const displayContent = () => {
    if (customHtml && customHtml.customHtml)
      return (
        /* eslint-disable-next-line react/no-danger */ <div
          dangerouslySetInnerHTML={{ __html: customHtml.customHtml }}
        />
      )
    if (content && content.json) return documentToReactComponents(content.json, options)
    return <div />
  }

  return { pageTitle, template, title, preTitle, description, content, customHtml, displayContent }
}
